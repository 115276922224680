import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import MainPageTitle from 'components/titles/MainPageTitle';
import "./UsesSectionStyles.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import { StoreContext } from 'store';




function UsesSection({ title, content, media, link_backward, link_forward }) {

    const store = React.useContext(StoreContext)

    return (
        <MainLayout>
            <div className={store.isMobile ? 'uses-page-mobile' : 'uses-page'}>
                <div className="page-title-line">
                    {!store.isMobile  && <MainPageTitle>{title}</MainPageTitle>}
                    <div className="nav-arrows" >
                        <Link to={link_backward}>
                            <FontAwesomeIcon icon={["fa", "arrow-left"]} className={"arrow left " + (link_backward? '': 'arrow-disabled')} size="2x" />
                        </Link>
                        {store.isMobile  && <MainPageTitle>{title}</MainPageTitle>}
                        <Link to={link_forward}>
                            <FontAwesomeIcon icon={["fa", "arrow-right"]} className={"arrow right " + (link_forward? '': 'arrow-disabled')} size="2x" />
                        </Link>
                    </div>
                </div>
                <div className="content-container content-container-1">
                    {store.isMobile && <div>
                        {media}
                    </div>}
                    <div className="content-container content-container-2">
                        <span>
                            {content}
                        </span>
                    </div>
                    {!store.isMobile && <><br /><div>
                        {media}
                    </div></>}
                </div>
            </div>
        </MainLayout>
    )
}

export default UsesSection;